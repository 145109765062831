.game {
    width: 100vw;
    height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.table {
    position: relative;
    margin-top: 20px;
    background-color: burlywood;
    border: 4px solid rgb(173, 136, 86);
    border-radius: 3px;
    width: 95%;
    max-width: 666px;
    height: 666px;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr 1fr 1fr 30%;
    grid-template-areas: 
    ". o3 o4 o5 ."
    "o2 c c c o6"
    "o1 c c c o7"
    "o0 c c c o8"
    "me me me me me";
}


/* ==========================================================================================
                PLAY AREA
========================================================================================== */

.play-area {
    position: relative;
    background-color: forestgreen;
    grid-area: c;

    display: grid;
    grid-template-columns: 20% 1fr 1fr 1fr 1fr 1fr 20%;
    grid-template-rows: 20% 1fr 1fr 1fr 1fr 1fr 20%;
    grid-template-areas: 
    ". . . . . . ."
    ". . p3 p4 p5 . ."
    ". p2 . . . p6 ."
    ". p1 . . . p7 ."
    ". p0 . . . p8 ."
    ". . . me . . ."
    ". . . . . . . ";
}

.pushed {
    animation: push 500ms ease forwards;
}

.trump {
    position: absolute;
    top: 0;
    right: 0;
    text-transform: capitalize;
    padding: 5px;
    color: var(--white);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.trump h3:first-child {
    font-size: 1.4em;
}

.trump img {
    width: 50px;
}

.p0,
.p1,
.p2,
.p3,
.p4,
.p5,
.p6,
.p7,
.p8,
.my-card {
    position: relative;
}

.p1 .card,
.p3 .card,
.p5 .card,
.p6 .card,
.p8 .card,
.p0 .card,
.p2 .card,
.p4 .card,
.p7 .card,
.my-card .card {
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
}

.p0 > div.card {
    transform: rotate(75deg);
}
.p1 > div.card {
    transform: translateX(-10px) rotate(90deg);
}
.p2 > div.card {
    transform: rotate(105deg);
}
.p3 > div.card {
    transform: rotate(-15deg);
}
.p4 > div.card {
    transform: translateY(-10px);
}
.p5 > div.card {
    transform: rotate(15deg);
}
.p6 > div.card {
    transform: rotate(75deg);
}
.p7 > div.card {
    transform: translateX(10px) rotate(90deg);
}
.p8 > div.card {
    transform: rotate(105deg);
}

.p0 {
    grid-area: p0;
}
.p1 {
    grid-area: p1;
}
.p2 {
    grid-area: p2;
}
.p3 {
    grid-area: p3;
}
.p4 {
    grid-area: p4;
}
.p5 {
    grid-area: p5;
}
.p6 {
    grid-area: p6;
}
.p7 {
    grid-area: p7;
}
.p8 {
    grid-area: p8;
}
.my-card {
    grid-area: me
}

@keyframes push {
    50% {
        grid-template-columns: 20% 1fr 1fr 1fr 1fr 1fr 20%;
        grid-template-rows: 20% 1fr 1fr 1fr 1fr 1fr 20%;
    }
    55% {
        grid-template-columns: 22% 1fr 1fr 1fr 1fr 1fr 22%;
        grid-template-rows: 22% 1fr 1fr 1fr 1fr 1fr 22%;
    }
    62% {
        grid-template-columns: 25% 1fr 1fr 1fr 1fr 1fr 25%;
        grid-template-rows: 25% 1fr 1fr 1fr 1fr 1fr 25%;
    }
    75% {
        grid-template-columns: 30% 1fr 1fr 1fr 1fr 1fr 30%;
        grid-template-rows: 30% 1fr 1fr 1fr 1fr 1fr 30%;
    }
    88% {
        grid-template-columns: 35% 1fr 1fr 1fr 1fr 1fr 35%;
        grid-template-rows: 35% 1fr 1fr 1fr 1fr 1fr 35%;
    }
    95%{
        grid-template-columns: 38% 1fr 1fr 1fr 1fr 1fr 38%;
        grid-template-rows: 38% 1fr 1fr 1fr 1fr 1fr 38%;
    }
    100%{
        grid-template-columns: 40% 1fr 1fr 1fr 1fr 1fr 40%;
        grid-template-rows: 40% 1fr 1fr 1fr 1fr 1fr 40%;
    }
}

/* ========================================================================================================
        END GAME
 ======================================================================================================== */

.end-screen {
    position: absolute;
    width: 90%;
    height: 90%;
    margin-left: -45%;
    /* margin-top: -45%; */

    left: 50%;
    top: 20px;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid black;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.end-screen h2 {
    text-align: center;
    margin: 25px;
    font-size: 3em;
}

.end-screen ul {
    list-style: none;
    width: 60%;
    background-color: var(--white);
    padding: 10px;
}

.end-screen ul li {
    border-bottom: 1px solid forestgreen;
    display: flex;
    height: 2.5em;
}

.end-screen ul p {
    font-size: 1.6em;
    flex: 1;
}

.end-screen ul p:last-child {
    text-align: right;
}

.end-screen ul span {
    flex: 1;
    text-align: center;
    font-size: 1.8em;
}

.new-game-btn:hover {
    background-color: var(--white);
    color: #333;
    cursor: pointer;
}

.new-game-btn {
   margin: auto 0 10px 0;
   background-color: #333;
   border: 2px solid #333;
   border-radius: 3px;
   padding: 10px 15px;
   
   color: var(--white);
   font-size: 1.3em;
   font-weight: bolder;

   transition:  all 300ms ease;
}

/* ========================================================================================================================
        BUTTONS
 ======================================================================================================================== */

 .buttons {
    position: absolute;
    bottom: 5px;
    left: 5px;
 }

 .scoreboard-btn:hover,
 .game .home-btn:hover {
     border-bottom: 2px solid #333;
     cursor: pointer;
 }

 .scoreboard-btn,
 .game .home-btn {
    
    background-color: burlywood;
    border: none;
    font-size: 1.2em;
    font-weight: bold;
    color: #333;
    border-bottom: 2px solid burlywood;
    padding: 0 10px;
}

.game .home-btn {
    border-left: 2px solid #333;
}

/* ========================================================================================================================
        SCOREBOARD
 ======================================================================================================================== */

 .scoreboard {
     position: absolute;
     height: 90%;
     width: 90%;
     top: 15px;
     background-color: rgba(255, 255, 255, 0.8);
     border: 2px solid #333;

     display: flex;
     flex-direction: column;
     align-items: center;

     z-index: 50;
     animation: grow 250ms ease forwards;
 }

 .scoreboard .x-btn:hover {
     cursor: pointer;
     background-color: var(--white);
     color: var(--red);
 }

 .scoreboard .x-btn {
     position: absolute;
     top: 5px;
     right: 5px;
     padding: 5px 10px;
     background-color: var(--red);
     color: var(--white);
     border: 2px solid var(--red);
     border-radius: 5px;

     font-size: 1.4em;
     font-weight: bolder;
 }

 .scoreboard h2 {
     text-align: center;
     margin: 10px;
 }

 .scoreboard table {
     width: 80%;
     background-color: var(--white);
     border-collapse: collapse;
 }

 .scoreboard table th:first-child {
    width: 6%;
 }

 .scoreboard table th {
     background-color: #333;
     color: var(--white);
     flex: 1;
     padding: 3px;
 }

 
 .scoreboard td,
 .scoreboard th {
     border: 2px solid var(--white);
}

.scoreboard table tr {
    border-bottom: 3px dotted #333;
}

.scoreboard table tr:first-child {
    border-bottom: none;
}

.scoreboard td {
    text-align: center;
}

.scoreboard td {
    font-size: 1.6em;
    font-weight: bolder;
    position: relative;
}

.scoreboard td span {
     color: #777;
    font-size: .6em;
     position: absolute;
     top: 0;
     left: 50%;
     margin-left: -15px;
 }

 @keyframes grow {
     0% {
         transform: scale(0) translate(100px);
     }

     100% {
        transform: scale(100%);
    }
 }