nav {
    background-color: #333;
    color: var(--white);
    height: 60px;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 7px;
}

nav a:hover {
    text-decoration: underline;
    color: #ccc;
}

nav a {
    color: var(--white);
    text-decoration: none;
}