.form-wrapper {
    width: 95%;
    max-width: 800px;
    background-color: white;
    padding: clamp(10px, 3%, 20px);
    border-radius: 4px;
    margin: 20px 0;
}

.form-wrapper form {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.form-wrapper form .input-wrapper {
    position: relative;
    margin: 10px 0;
}

.form-wrapper .input-wrapper input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 20px 10px 10px 10px;
    font-size: 1.3em;
    color: #777;
}

.form-wrapper .input-wrapper input:focus {
    outline: none;
}

.form-wrapper .input-wrapper label {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: #777;
    border-bottom: 2px solid #333;
}

.form-wrapper .input-wrapper label span {
    position: absolute;
    left: 10px;
    top: 10px;
    font-size: 1.6em;

    transition: all 700ms ease-in-out;
}

.form-wrapper .input-wrapper label::after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    bottom: -4px;
    left: -100%;
    border-bottom: 4px solid forestgreen;

    transition: all 700ms ease-in-out;
}

.form-wrapper .input-wrapper input:focus + label span,
.form-wrapper label.filled span {
    top: -15px;
    color: #333;
}

.form-wrapper .input-wrapper input:focus + label::after,
.form-wrapper label.filled::after {
    left: 0;
}

.form-wrapper .select-btn {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.form-wrapper button[type=submit]:hover {
    background-color: var(--white);
    color: forestgreen;
    cursor: pointer;
}

.form-wrapper button[type=submit] {
    background-color: forestgreen;
    border: 2px solid forestgreen;
    border-radius: 5px;

    margin: 0 auto;
    padding: 10px 20px;
    
    color: var(--white);
    font-size: 2em;
    font-weight: bolder;

    transition: all 300ms ease;
}