
/* ==========================================================================================
               USER AREA
========================================================================================== */


.user-ui {
    grid-area: me;
    /* border: 1px solid black; */

    display: grid;
    grid-template-columns: 70% 30%;
    grid-template-rows: 10% 90%;
    grid-template-areas: 
    "title ui"
    "cards ui";

    position: relative;
}

.user-ui h2 {
    grid-area: title;
    text-align: center;
    font-size: 1.7em;
    font-weight: bold;
    text-transform: capitalize;
}

.user-ui h2 span {
    font-size: .8em;
    color: #333;
}

.your-turn {
    position: absolute;
    top: -100px;
    left: 50%;

    margin-left: -250px;
    width: 500px;
    font-size: 4em;
    color: var(--white);
    text-align: center;

    animation: pulse 1700ms ease infinite;
}

.user-hand {
    grid-area: cards;
    padding: 10px;
    list-style: none;
    display: flex;
    justify-content: center;
}

.user-hand li {
    /* overflow: hidden; */
    max-width: 104px;
    padding-top: 10px;
}

.user-hand li:last-child {
    overflow: visible;
    width: 104px;
}

.user-ui .card {
    margin: 0;
}
.user-ui .user-hand .card:hover {
    transform: translateY(-5px);
}

.reneged {
    animation: reneg 750ms ease;
    /* transition: transform 500ms ease; */
}

/* ==========================================================================================
               POINTS AREA
========================================================================================== */

.user-options {
    grid-area: ui;
    position: relative;
    /* border: 1px solid black; */
}

.points-etc .points,
.small-points {
    position: absolute;
    bottom: -10px;
    right: 10px;

    font-size: 6em;
    font-weight: bolder;
}

.points-etc .points span {
    font-size: .3em;
    color: #333;
    /* margin-right: 10px; */
}

.my-tricks {
    list-style: none;
    display: flex;
    /* flex-direction: row-reverse;
    justify-content: flex-start; */
    padding: 5px 15px;
}

.my-tricks li {
    width: 15px;
    position: relative;
}

.my-tricks li div {
    position: relative;
    width: 30px;
    height: 38px;
}

.my-tricks li p {
    position: absolute;
    z-index: 27;
    left: 8px;
    top: 3px;

    font-size: 1.7em;
    font-weight: bold;
    color: var(--white);
}

.user-dealer {
    position: absolute;
    bottom: 5px;
    background-color: #333;
    border-radius: 10px;
}

.user-dealer:hover .card:first-child,
.user-dealer:hover p {
    transform: translateY(-7px);
}

.user-dealer .card:first-child {
    position: absolute;
    bottom: 5px;
}

.user-dealer p {
    position: absolute;
    top: 50%;
    margin-top: -15px;
    width: 100%;
    height: 30px;
    text-align: center;

    color: var(--black);
    font-size: 1.5em;
    font-weight: bolder;
    pointer-events: none;

    transition: all 250ms ease;
}

.card {
    transition: all 250ms ease;
}

.user-dealer-symbol {
    position: absolute;
    bottom: 5px;
    width: 40px;
    height: 40px;
    border: 2px solid var(--white);
    border-radius: 50%;
    background-color: #333;
    color: var(--white);
    font-size: 1.5em;
    font-weight: bolder;

    display: flex;
    justify-content: center;
    align-items: center;
}

/* ==========================================================================================
               BET AREA
========================================================================================== */


.bet {
    width: 100%;
    height: 800px;
    padding: 20px 35px;

    position: absolute;
    left: 0;
    bottom: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.bet-ctrl {
    pointer-events: visible;
    margin-top: -20px;
}

.bet-ctrl div {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 4em;
    font-weight: bold;
}

.bet-ctrl div h3 {
    margin: 0 10px -10px 10px;
}

.bet-ctrl div h4:hover {
    color: #555;
    cursor: pointer;
    transition: color 250ms ease;
}

.bet-ctrl div button:hover,
.bet-ctrl div button:active,
.bet-ctrl div button:focus,
.bet-ctrl > button:hover,
.bet-ctrl > button:active,
.bet-ctrl > button:focus {
    outline: none;
    background-color: #555;
    cursor: pointer;
}

.bet-ctrl button {
    width: 25px;
    height: 20px;
    
    background-color: #333;
    border: none;
    color: var(--white);
    border-radius: 3px;
    font-size: 1.2em;
    padding: 5px;
    font-weight: bolder;

    transition: all 300ms ease;
}

.bet-ctrl > button {
    margin-top: 10px;
    width: 100%;
    height: 30px;
}

.whos-betting {
    color: var(--white);
    text-transform: capitalize;
    font-size: larger;
}

@keyframes pulse {
    0% {
        opacity: 0.1;
    }
    30% {
        opacity: 1;
    }
    50% {
        opacity: 1;
    }
    30% {
        opacity: 1;
    }
    100% {
        opacity: 0.1;
    }
}

@keyframes reneg {
    0% {
        box-shadow: 0 0 3px rgba(226, 34, 34, 0.8);
        transform: rotate(3deg) translateY(-5px);
    }
    25% {
        box-shadow: 0 0 3px rgba(226, 34, 34, 0.8);
        transform: rotate(-3deg) translateY(-5px);
    }
    50% {
        box-shadow: 0 0 3px rgba(226, 34, 34, 0.8);
        transform: rotate(3deg) translateY(-5px);
    }
    75% {
        box-shadow: 0 0 3px rgba(226, 34, 34, 0.8);
        transform: rotate(-3deg) translateY(-5px);
    }
    100% {
        box-shadow: 0 0 3px rgba(226, 34, 34, 0.8);
        transform: rotate(3deg) translateY(-5px);
    }
}