@charset "UTF-8";
.card {
  box-sizing: border-box;
  display: inline-block;
  margin: 0.125em;
  cursor: pointer;
  /* Set the card's dimensions, and size it relative to
     it's font size. */
  font-size: inherit;
  width: 4.0em;
  height: 5.5em;
  /* Add a default background color and border. */
  background-color: #fff;
  border-radius: 0.20em;
  border: 1px solid #c3c3c3;
  /* Scale and set the background settings. */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  /* "Hide" any text within the element (use text to be
     simply compliant with screen readers, etc.). */
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  /* Default sizes for cards (size based off the font). */
  /* Optional styles for cards: borderless and drop shadow. */
  /* Not a card, but a card-shaped outline to represent a
     droppable place, like an empty pile. */ }
  .card.small {
    font-size: 12px; }
  .card.large {
    font-size: 26px; }
  .card.xlarge {
    font-size: 42px; }
  .card.no-border {
    border: 0; }
  .card.shadow {
    box-shadow: 2px 2px 3px 0 rgba(41, 41, 41, 0.75); }
  .card.outline {
    background-image: none;
    background-color: rgba(195, 195, 195, 0.5);
    border: 0.15em dashed #daa520; }

.card.diamonds.A,
.card.dA,
.card.♦A {
  background-image: url("../images/diamonds/diamonds-A.svg"); }

.card.diamonds.K,
.card.dK,
.card.♦K {
  background-image: url("../images/diamonds/diamonds-K.svg"); }

.card.diamonds.Q,
.card.dQ,
.card.♦Q {
  background-image: url("../images/diamonds/diamonds-Q.svg"); }

.card.diamonds.J,
.card.dJ,
.card.♦J {
  background-image: url("../images/diamonds/diamonds-J.svg"); }

.card.diamonds.r10,
.card.d10,
.card.♦10 {
  background-image: url("../images/diamonds/diamonds-r10.svg"); }

.card.diamonds.r09,
.card.d09,
.card.♦09 {
  background-image: url("../images/diamonds/diamonds-r09.svg"); }

.card.diamonds.r08,
.card.d08,
.card.♦08 {
  background-image: url("../images/diamonds/diamonds-r08.svg"); }

.card.diamonds.r07,
.card.d07,
.card.♦07 {
  background-image: url("../images/diamonds/diamonds-r07.svg"); }

.card.diamonds.r06,
.card.d06,
.card.♦06 {
  background-image: url("../images/diamonds/diamonds-r06.svg"); }

.card.diamonds.r05,
.card.d05,
.card.♦05 {
  background-image: url("../images/diamonds/diamonds-r05.svg"); }

.card.diamonds.r04,
.card.d04,
.card.♦04 {
  background-image: url("../images/diamonds/diamonds-r04.svg"); }

.card.diamonds.r03,
.card.d03,
.card.♦03 {
  background-image: url("../images/diamonds/diamonds-r03.svg"); }

.card.diamonds.r02,
.card.d02,
.card.♦02 {
  background-image: url("../images/diamonds/diamonds-r02.svg"); }

.card.hearts.A,
.card.hA,
.card.♥A {
  background-image: url("../images/hearts/hearts-A.svg"); }

.card.hearts.K,
.card.hK,
.card.♥K {
  background-image: url("../images/hearts/hearts-K.svg"); }

.card.hearts.Q,
.card.hQ,
.card.♥Q {
  background-image: url("../images/hearts/hearts-Q.svg"); }

.card.hearts.J,
.card.hJ,
.card.♥J {
  background-image: url("../images/hearts/hearts-J.svg"); }

.card.hearts.r10,
.card.h10,
.card.♥10 {
  background-image: url("../images/hearts/hearts-r10.svg"); }

.card.hearts.r09,
.card.h09,
.card.♥09 {
  background-image: url("../images/hearts/hearts-r09.svg"); }

.card.hearts.r08,
.card.h08,
.card.♥08 {
  background-image: url("../images/hearts/hearts-r08.svg"); }

.card.hearts.r07,
.card.h07,
.card.♥07 {
  background-image: url("../images/hearts/hearts-r07.svg"); }

.card.hearts.r06,
.card.h06,
.card.♥06 {
  background-image: url("../images/hearts/hearts-r06.svg"); }

.card.hearts.r05,
.card.h05,
.card.♥05 {
  background-image: url("../images/hearts/hearts-r05.svg"); }

.card.hearts.r04,
.card.h04,
.card.♥04 {
  background-image: url("../images/hearts/hearts-r04.svg"); }

.card.hearts.r03,
.card.h03,
.card.♥03 {
  background-image: url("../images/hearts/hearts-r03.svg"); }

.card.hearts.r02,
.card.h02,
.card.♥02 {
  background-image: url("../images/hearts/hearts-r02.svg"); }

.card.spades.A,
.card.sA,
.card.♠A {
  background-image: url("../images/spades/spades-A.svg"); }

.card.spades.K,
.card.sK,
.card.♠K {
  background-image: url("../images/spades/spades-K.svg"); }

.card.spades.Q,
.card.sQ,
.card.♠Q {
  background-image: url("../images/spades/spades-Q.svg"); }

.card.spades.J,
.card.sJ,
.card.♠J {
  background-image: url("../images/spades/spades-J.svg"); }

.card.spades.r10,
.card.s10,
.card.♠10 {
  background-image: url("../images/spades/spades-r10.svg"); }

.card.spades.r09,
.card.s09,
.card.♠09 {
  background-image: url("../images/spades/spades-r09.svg"); }

.card.spades.r08,
.card.s08,
.card.♠08 {
  background-image: url("../images/spades/spades-r08.svg"); }

.card.spades.r07,
.card.s07,
.card.♠07 {
  background-image: url("../images/spades/spades-r07.svg"); }

.card.spades.r06,
.card.s06,
.card.♠06 {
  background-image: url("../images/spades/spades-r06.svg"); }

.card.spades.r05,
.card.s05,
.card.♠05 {
  background-image: url("../images/spades/spades-r05.svg"); }

.card.spades.r04,
.card.s04,
.card.♠04 {
  background-image: url("../images/spades/spades-r04.svg"); }

.card.spades.r03,
.card.s03,
.card.♠03 {
  background-image: url("../images/spades/spades-r03.svg"); }

.card.spades.r02,
.card.s02,
.card.♠02 {
  background-image: url("../images/spades/spades-r02.svg"); }

.card.clubs.A,
.card.cA,
.card.♣A {
  background-image: url("../images/clubs/clubs-A.svg"); }

.card.clubs.K,
.card.cK,
.card.♣K {
  background-image: url("../images/clubs/clubs-K.svg"); }

.card.clubs.Q,
.card.cQ,
.card.♣Q {
  background-image: url("../images/clubs/clubs-Q.svg"); }

.card.clubs.J,
.card.cJ,
.card.♣J {
  background-image: url("../images/clubs/clubs-J.svg"); }

.card.clubs.r10,
.card.c10,
.card.♣10 {
  background-image: url("../images/clubs/clubs-r10.svg"); }

.card.clubs.r09,
.card.c09,
.card.♣09 {
  background-image: url("../images/clubs/clubs-r09.svg"); }

.card.clubs.r08,
.card.c08,
.card.♣08 {
  background-image: url("../images/clubs/clubs-r08.svg"); }

.card.clubs.r07,
.card.c07,
.card.♣07 {
  background-image: url("../images/clubs/clubs-r07.svg"); }

.card.clubs.r06,
.card.c06,
.card.♣06 {
  background-image: url("../images/clubs/clubs-r06.svg"); }

.card.clubs.r05,
.card.c05,
.card.♣05 {
  background-image: url("../images/clubs/clubs-r05.svg"); }

.card.clubs.r04,
.card.c04,
.card.♣04 {
  background-image: url("../images/clubs/clubs-r04.svg"); }

.card.clubs.r03,
.card.c03,
.card.♣03 {
  background-image: url("../images/clubs/clubs-r03.svg"); }

.card.clubs.r02,
.card.c02,
.card.♣02 {
  background-image: url("../images/clubs/clubs-r02.svg"); }

.card.joker {
  background-image: url("../images/jokers/joker.svg"); }

.card.joker-black {
  background-image: url("../images/jokers/joker-black.svg"); }

.card.joker-red {
  background-image: url("../images/jokers/joker-red.svg"); }

.card.back {
  background-image: url("../images/backs/blue.svg"); }

.card.back-blue {
  background-image: url("../images/backs/blue.svg"); }

.card.back-red {
  background-image: url("../images/backs/red.svg"); }

/* Re-orients the cards for different directions. While
   they are symmetric, it's still important to orient
   them correctly in case text or other symbols are
   layered on them with CSS pseudo-selectors, new
   classes/rules, etc. */
.card.west,
.card.W {
  transform: rotate(90deg);
  margin: auto 0.75em; }
  .card.west.shadow,
  .card.W.shadow {
    box-shadow: 2px -2px 3px 0 rgba(41, 41, 41, 0.75); }

.card.north,
.card.N {
  transform: rotate(180deg); }
  .card.north.shadow,
  .card.N.shadow {
    box-shadow: -2px -2px 3px 0 rgba(41, 41, 41, 0.75); }

.card.east,
.card.E {
  transform: rotate(270deg);
  margin: auto 0.75em; }
  .card.east.shadow,
  .card.E.shadow {
    box-shadow: -2px 2px 3px 0 rgba(41, 41, 41, 0.75); }
