.lobby {
    width: 100vw;
    height: calc(100vh - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.writing {
    width: 60%;
    text-align: center;
    margin: 20px;
}

.writing h2,
.writing p {
    margin: 15px;
}

.link {
    padding: 10px 20px;
    background-color: rgb(233, 233, 162);
    font-weight: 900;
    color: rgb(92, 142, 189);
    text-decoration: underline;
    cursor: copy;
    position: relative;
}

.copied {
    position: absolute;
    right: 0;
    top: 0;
    width: 100px;
    animation: fadeout 1s ease forwards;
    background-color: white;
    padding: 10px;
    border: 1px solid #333;
}

.lobby .user-list {
    width: 60%;
    border: 2px solid #333;
    border-radius: 5px;
    padding: 10px;
}

.lobby .user-list h4 {
    text-align: center;
}

.lobby .user-list ul {
    list-style: none;
    width: 100%;
}

.lobby .user-list ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    border-bottom: 1px solid forestgreen
}

.lobby .user-list ul li span {
    flex: 1;
    /* text-align: left; */
}

.lobby li .button {
    position: relative;
    display: block;
    width: 24px;
    height: 24px;
    margin-left: 10px;
    border-radius: 3px;
    background-color: var(--red);
    border: 2px solid var(--red);

    transition: all 400ms ease;
}

.lobby li .button:hover {
    background-color: white;
    cursor: pointer;
}

.lobby li .button:hover .x{
    background-color: var(--red);
}

.lobby .x {
    position: absolute;
    top: 7px;
    left: 0;
    width: 20px;
    height: 5px;
    transform: rotate(45deg);
    border-radius: 10px;
    background-color: white;

    transition: all 400ms ease;
}
.lobby .x:last-child {
    transform: rotate(-45deg);

}

.lobby .form-wrapper form {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.lobby .form-wrapper .input-wrapper {
    width: 75%;
}

.lobby .form-wrapper button {
    width: 20%;
    height: 50px;

    border: 2px solid forestgreen;
    background-color: forestgreen;
    color: white;
    font-size: 2em;
    font-weight: 600;
    border-radius: 4px;
    transition: all 300ms ease-in-out;
}

.lobby .form-wrapper button:hover {
    cursor: pointer;
    background-color: white;
    color: forestgreen;
}

.start-game-btn:hover {
    background-color: var(--white);
    color: #333;
    cursor: pointer;
}

.start-game-btn {
    background-color: #333;
    color: var(--white);
    padding: 15px 25px;
    border: 2px solid #333;
    border-radius: 3px;;
    margin: 10px;

    font-size: 1.3em;
    font-weight: bold;

    transition: all 300ms ease;
}

.go-home:hover {
    text-decoration: underline;
    color: #ccc;
    cursor: pointer;
}

.go-home {
    position: absolute;
    top: 10px;
    left: 10px;
    font-size: 2em;
    color: var(--white);
}

.lobby tr {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lobby th {
    margin-right: 10px;
}

.lobby td {
    display: flex;
    font-size: 1.3em;
    align-items: center;
}

.lobby td p:hover {
    text-decoration: underline;
}

.lobby td p {
    margin-left: 10px;
    font-size: .8em;
    cursor: pointer;
}

form.edit-upto input {
    width: 50px;
}

/* ================================================================================================================
            ANIMATIONS
================================================================================================================ */


@keyframes fadeout {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 1;
        display: block;
    }
    100% {
        opacity: 0;
        display: none;
    }
}