.home {
    width: 100vw;
    height: calc(100vh - 60px);

    display: flex;
    flex-direction: column;
    align-items: center;
}

.home > p {
    width: 100%;
    max-width: 800px;

    margin: 25px;
}

